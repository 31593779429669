import React from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass, faClock } from '@fortawesome/free-regular-svg-icons';

export default function JobListing({ job, handleDrawer, drawerOpen }) {
    const { id, title, city, state, type, description, board_code } = job;
    const location = state && city ? `${city}, ${state}` : (city ? city : state);

    const removeInlineStyles = (content) => {
        const inlineStyles = /style=("|\')(.*?)("|\')|<span[^>]+\>|<\/span>|<p[^>]*>(?:\s|&nbsp;)*<\/p>/gim;
        return content.replaceAll(inlineStyles, '');
    };

    const details = [
        {
            icon: faCompass,
            text: location,
        },
        {
            icon: faClock,
            text: type,
        },
    ];

    const content = (
        <>
            <div className='prose'>{parse(removeInlineStyles(description))}</div>

            <div className='flex flex-shrink-0 justify-end px-4 py-4'>
                <a
                    id={`trigger-${id}`}
                    className='btn btn-accent'
                    onClick={() => setOpen(false)}
                    data-job-id={board_code}
                    data-job-title={title}
					title="JazzHR Apply page opens in new tab"
                    href={`https://archermalmo.applytojob.com/apply/${id}?source=Our%20Careers%20Page`}
                    target='_blank'
                >
                    Apply Now
                </a>
            </div>
        </>
    );

    return (
        <div id={`amJob-${id}`} className={`amJob pt-6 pb-8 flex flex-col gap-3`}>
            <div className='prose'>
                <h4 className='amJob__title text-lg text-accent-500 underline mt-0'
                onClick={() => handleDrawer(!drawerOpen, title, content)}>{title}</h4>
            </div>
            <div className='flex gap-3 text-base'>
                {details.map((detail, i) => (
                    <span key={i} className='flex items-center text-foreground-800'>
                        <FontAwesomeIcon icon={detail.icon} className='block mr-2 mt-0.5 text-foreground' />
                        <span className='block'>{detail.text}</span>
                    </span>
                ))}
            </div>
            <button
                className='text-accent-800 hover:text-accent-900 text-sm text-left'
                onClick={() => handleDrawer(!drawerOpen, title, content)}
            >
                Show Details
            </button>
        </div>
    );
}
