import React, { useEffect, useState } from 'react';
import PostCard from '../../latest/components/PostCardNew';
// import FilterBar from './FilterBar';
// import parse from 'html-react-parser/dist/html-react-parser';
import { motion, AnimatePresence } from 'framer-motion';

// import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
// import { useNavigate, Link } from 'react-router-dom';

const bgBlob = new URL('../../../images/postBG_blob.png?as=webp&quality=45&width=2000', import.meta.url);

// import Palm_Beach_Tan from  '../../../images/work_thumbnails/Palm_Beach_Tan.jpg';
// import Grizzly_Tobacco from  '../../../images/work_thumbnails/Grizzly.jpg';
// import Wind_Creek_Hospitality from  '../../../images/work_thumbnails/Wind_Creek_Hospitality.jpg';
// import Virbac from  '../../../images/work_thumbnails/Virbac.jpg';
// import Cotton_Board from  '../../../images/work_thumbnails/Cotton_Board.jpg';
// import Southland_Casino_Hotel from  '../../../images/work_thumbnails/Southland_Casino_Hotel.jpg';
// import Zoetis from  '../../../images/work_thumbnails/Zoetis.png';
// import Hyatt_Hotels_and_Resorts from  '../../../images/work_thumbnails/Hyatt.jpg';
// import Hampline_Brewing_Company from  '../../../images/work_thumbnails/Hampline_Brewery.jpg';
// import Capital_Farm_Credit from  '../../../images/work_thumbnails/Capital_Farm_Credit.jpg';
// import Smile_Doctors from  '../../../images/work_thumbnails/Smile_Doctors.avif';
// import Cytozyme from  '../../../images/work_thumbnails/Cytozyme.avif';
// import Hanger_Clinic from  '../../../images/work_thumbnails/Hanger_Clinic.jpg';
// import HPM from  '../../../images/work_thumbnails/HPM.avif';
// import Simpas from  '../../../images/work_thumbnails/Simpas.jpg';
// import Massage_Heights from  '../../../images/work_thumbnails/Massage_Heights.gif';

// const tempWorkData = [
//     [
//         Palm_Beach_Tan,
//         'Palm Beach Tan',
//         'branding, web design, video, outdoor',
//         "portrait",
//         '/case-studies/services-retail/palm-beach-tan',
//         'Delivering resonance and revenue with a head-to-toe brand makeover.',
//         "https://gmartin.dev.amdevel.com/video/PBT.mp4",
//       ],[
//         Grizzly_Tobacco,
//         'Grizzly Tobacco',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/misc/grizzly',
//         'Building a category leading brand through strong storytelling.'
//       ],[
//         Wind_Creek_Hospitality,
//         'Wind Creek Hospitality',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/hospitality/wind-creek-hospitality',
//         'Uniting the online and on-property guest experience to win big.'
//       ],[
//         Virbac,
//         'Virbac ',
//         'branding, web design, video, outdoor',
//         "portrait",
//         '/case-studies/pet-care/virbac',
//         'Standing out in a crowded category with breakthrough creative.',
//         "Virbac.mp4",
//       ],[
//         Cotton_Board,
//         'Cotton Board',
//         'branding, web design, video, outdoor',
//         "extrawide",
//         '/case-studies/agriculture/cotton-board',
//         'Leveraging emotional storytelling for a unique B2B approach.'
//       ],[
//         Southland_Casino_Hotel,
//         'Southland Casino Hotel',
//         'branding, web design, video, outdoor',
//         "portrait",
//         '/case-studies/hospitality/southland-casino',
//         'Betting on authenticity to change brand perceptions.',
//         "Southland.mp4",
//       ],[
//         Zoetis,
//         'Zoetis ',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/pet-care/zoetis',
//         'Unleashing a new market opportunity.',
//       ],[
//         Hyatt_Hotels_and_Resorts,
//         'Hyatt Hotels and Resorts',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/misc/smile-doctors',
//         'Bringing a unique in-clinic experience to life at scale.',
//         "Hyatt.mp4",
//       ],[
//         Hampline_Brewing_Company,
//         'Hampline Brewing Company',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/agriculture/cytozyme',
//         'Driving discovery, delivering distribution for a new market entrant.',
//       ],[
//         Capital_Farm_Credit,
//         'Capital Farm Credit',
//         'branding, web design, video, outdoor',
//         "extrawide",
//         '/case-studies/misc/hanger-clinic',
//         'Delivering a patient-centric digital experience to enhance client acquisition.',
//         "CFC.mp4",
//       ],[
//         Smile_Doctors,
//         'Smile Doctors',
//         'branding, web design, video, outdoor',
//         "portrait",
//         '/case-studies/hospitality/hyatt-hotels',
//         'Increasing profitability by driving direct traffic and bookings.',
//       ],[
//         Cytozyme,
//         'Cytozyme ',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/misc/hampline-brewing-company',
//         'Designing an identity as distinct as the destination.',
//       ],[
//         Hanger_Clinic,
//         'Hanger Clinic',
//         'branding, web design, video, outdoor',
//         "portrait",
//         '/case-studies/pet-care/hpm',
//         'Building a brand that meets an unknown need through education and awareness',
//       ],[
//         HPM,
//         'HPM ',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/agriculture/simpas',
//         'Bringing unprecedented technology to market in unprecedented times',
//       ],[
//         Simpas,
//         'Simpas ',
//         'branding, web design, video, outdoor',
//         "landscape",
//         '/case-studies/services-retail/massage-heights',
//         'Unifying system-wide strategies to help sales soar.',
//       ],[
//         Massage_Heights,
//         'Massage Heights',
//         'branding, web design, video, outdoor',
//         "portrait",
//         '/case-studies/agriculture/capital-farm-credit',
//         'Helping rural communities grow by delivering new audiences for impact',
//       ]
// ];
export default function FilterGrid({ posts, loaded }) {
    const [displayData, setDisplayData] = useState(posts);
    // const [active, setActive] = useState('all');
    // const [filters, setFilters] = useState([]);

    // const handleCategoryClick = (category) => {
    //     if (category === active) return;
    //     setActive(category);

    //     if (category === 'all') {
    //         setDisplayData(posts);
    //         return;
    //     }

    //     const filteredData = posts.filter((item) => item.term === category);
		//   setDisplayData(filteredData);
    // };

    //Get categories from posts - move this to the parent if you need a different kind of filter
    // useEffect(() => {
    //   let categories = [];
    //   if (Array.isArray(posts)) {
    //       categories = [...new Set(posts.map((post) => post?.term ))];
    //   }

    //   // Remove misc from filters
    //   const index = categories.indexOf('misc');
    //   categories.splice(index, 1);

    //   setFilters(categories);
    // }, [posts]);

    return (
        <div
            style={{ '--image-url': `url(${bgBlob})` }}
            className={`bg-foreground-100 md:bg-[image:var(--image-url)] bg-contain bg-no-repeat bg-[center_top_2rem]`}
        >
            <div className='overflow-visible container'>
                {/* <div className='lg:flex lg:justify-center -mx-4 mb-8 py-2 overflow-x-scroll'>
                    <div className='px-4'>
                        <FilterBar active={active} handleClick={handleCategoryClick} items={filters} withAll />
                    </div>
                </div> */}

                <div className='justify-between items-stretch gap-x-10 gap-y-12 sm:gap-y-16 grid grid-cols-1 sm:grid-cols-2 mx-auto pt-10 pb-60 max-w-5xl'>
                    <AnimatePresence>
                        {posts
                            ? displayData.map((post, i) => {
                                  return loaded ? (
                                      <motion.div
                                          key={i}
                                          initial={{ opacity: 0, y: -200, rotate: 4 }}
                                          animate={{ opacity: 1, y: 0, rotate: 0 }}
                                          exit={{ opacity: 0, y: 200, rotate: 4 }}
                                          className={`
                                            flex items-center mb-6 hover:text-background transition-colors group relative self-center
                                            ${post.thumbnail_orientation === 'landscape' ? 'col-span-1' : ''}
                                            ${post.thumbnail_orientation === 'portrait' ? 'col-span-1' : ''}
                                            ${post.thumbnail_orientation === 'extrawide' ? 'sm:col-span-2' : ''}
                                            `}
                                      >
                                          <PostCard post={post} key={`post-${post.id}`} />
                                      </motion.div>
                                  ) : (
                                      <SkeletonCard lines={8} key={i} />
                                  );
                              })
                            : null}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}
