import React from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import ContentCta from './ContentCta';

export default function ContentSlide({ title, description, cta }) {
    
    return (
        <div className='bg-background-500 flex flex-col h-full justify-end'>
            <div className='px-5 py-8 text-white text-pretty'>
                {title && <h3 className='font-sans text-2xl mb-2 leading-7'>{title}</h3>}
                {description && <div className='text-base prose text-white [&>p>a]:text-accent'>{parse(description)}</div>}
                {cta.has_cta && <ContentCta {...cta} />}
            </div>
        </div>
    );
}
