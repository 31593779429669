/**
 * Block | Related Content Block
 * 
 * @param
 * relatedContent
 */

import React from 'react';
import { motion } from 'framer-motion';
import api from '/src/utils/api';
import makePosts from '/src/utils/makePosts';
import PostsGrid from '../../latest/components/PostsGrid';


export default function RelatedContent(props) {
	const [loaded, setLoaded] = React.useState(false);
	const [posts, setPosts] = React.useState(null);
	const ids = props.relatedContent.join();

	React.useEffect(() => {
        api.getData(`posts?type[]=post&type[]=page&type[]=case-study&include=${ids}&_embed&acf_format=standard`)
            .then((response) => {
				// console.log(response.data)
				setPosts(makePosts(response.data));
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);
    return (
		
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className={``}
        >
            <PostsGrid posts={loaded ? posts : [...Array(3)]} loaded={loaded} heading="Keep Exploring" layout="casestudy" />
        </motion.section>
    )
}
