import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import api from '../../utils/api';
import Wrapper from '../../components/wrapper';
import CSBlocks from './cs-blocks/CSBlocks';
import { motion } from 'framer-motion';
import Seo from '/src/components/Seo';
import makeSeo from '../../utils/makeSeo';

const defaultSeo = {
    title: 'Case Study | Archer',
    description: 'Our work inspires us to set our sights higher. Get inspired and see what we can do for you.',
};

export default function CaseStudy(props) {
    const location = useLocation();
    const { slug } = useParams();
    const errorPage = '/';
    if (!slug) return <Navigate to={errorPage} />;

    const [loaded, setLoaded] = useState(false);
    const [content, setContent] = useState(null);
    const [seo, setSeo] = useState(defaultSeo);

    const pageContent = (data) => {
        let dataContent = {
            blocks: data.acf.flexible_blocks,
        };
        setContent(dataContent);
    };

    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    useEffect(() => {
		setLoaded(false);
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        api.getData(`case-study/?slug=${slug}&_embed&acf_format=standard`)
            .then((response) => {
                // console.log(response.data);
                pageContent(response.data[0]);
                setLoaded(true);
                getSeo(response.data[0].yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, [slug]);

    let countBlocks = 0;
    let needWhitespace = false;
    return (
        <>
            <Seo title={seo.title} description={seo.description} />

            <Wrapper theme='light' openContactForm={props.openContactForm}>
                {!loaded ? (
                    <div className='h-screen'></div>
                ) : (
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0, delay: 0 }}
                    >
                        {content &&
                         content.blocks &&
                         content.blocks.map((block, index) => {
                            countBlocks++;
                            // We add an extra empty div at the end if the last block is one that does not have a white background.
                            // This is to make the transition into the footer smoother.
                            if (countBlocks === content.blocks.length &&
                               (block.acf_fc_layout === 'quotes' || 
                                block.acf_fc_layout === 'fullwidth_image' || 
                                block.acf_fc_layout === 'video')
                            ) {
                                needWhitespace = true;
                            }

                            return (
                                <CSBlocks
                                    block={block}
                                    key={index}
                                    lastItem={countBlocks === content.blocks.length}
                                />
                            )
                        })}

                        {/* This is that empty div to add white space if last block has colored background */}
                        {content && needWhitespace && <div className='bg-foreground-100 py-8' />}
                    </motion.div>
                )}
            </Wrapper>
        </>
    );
}
