/**
 * Block | Content with Image Block (text_image)
 *
 * @param
 * heading
 * Section title
 *
 * headingStyle
 * h2 or h3 element for the heading
 *
 * content
 * Section content
 *
 * image
 * Optional section image
 *
 * imagePosition
 * true = right
 * false = left
 * 
 * mobileImagePosition
 * true = bottom
 * false = top
 *
 * centered
 * Horizontal centering of content if no image is present
 *
 * centeredVertical
 * Vertical centering of content based on image height
 */

import React from 'react';
import { motion } from 'framer-motion';

export default function TextImage(props) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className='block__textimage
            bg-foreground-100
            py-8 xl:py-12'
        >
            <div className='container xl:max-w-7xl'>
                <div
                    className={`grid gap-10 xl:gap-36
                    ${
                        props.image ?
                        (props.centeredVertical ? 'items-center lg:grid-cols-2' : 'lg:grid-cols-2') :
                        (props.centered ? 'justify-center' : '')
                    }`}
                >
                    <div className={`${props.mobileImagePosition ? 'order-1' : 'order-2'} ${props.imagePosition ? 'lg:order-1' : 'lg:order-2'}`}>
                        {props.heading && props.headingStyle && props.headingStyle === 'h2' && (
                            <h2 className='text-3xl md:text-4xl text-accent uppercase font-normal tracking-wide mb-5'>
                                {props.heading}
                            </h2>
                        )}
                        {props.heading && props.headingStyle && props.headingStyle === 'h3' && (
                            <h3 className='text-2xl text-accent uppercase font-normal tracking-wide mb-5'>
                                {props.heading}
                            </h3>
                        )}
                        {props.content && (
                            <div
                                className={`prose prose-lg`}
                                dangerouslySetInnerHTML={{ __html: props.content }}
                            />
                        )}
                    </div>
                    {props.image && (
                        <div className={`block ${props.mobileImagePosition ? 'order-2' : 'order-1'} ${props.imagePosition ? 'lg:order-2' : 'lg:order-1'}`}>
                            <img
                                src={props.image['url']}
                                alt={props.image['alt']}
                                className='mx-auto w-full max-w-lg lg:max-w-none md:w-5/6 lg:w-full'
                            />
                        </div>
                    )}
                </div>
            </div>
        </motion.section>
    )
}
