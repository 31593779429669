import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

export default function PostCard({ post }) {
    const navigate = useNavigate();
    const toPost = () => {
        navigate(`/latest/${post.slug}/`, { state: { id: post.id } });
    };

    return (
        <div className='relative max-w-sm text-foreground-700 group'>
            <div className='relative'>
                <div className='relative bg-background mb-6 overflow-hidden aspect-photo'>
                    {post.image2 ? (
                        <>
                            <img
                                className='absolute inset-0 opacity-0 group-hover:opacity-100 w-[400] h-[300] transition object-cover'
                                src={`${post.image2}&w=800&h=600`}
                                width='400'
                                height='300'
                                alt={post.image2_alt}
                                role={post.image2_alt ? 'img' : 'presentation'}
                            />
                            <img
                                className='opacity-100 group-hover:opacity-0 w-[400] h-[300] transition object-cover'
                                src={`${post.image}&w=800&h=600`}
                                width='400'
                                height='300'
                                alt={post.image_alt}
                                role={post.image_alt ? 'img' : 'presentation'}
                            />
                        </>
                    ) : (
                        <img
                            className='group-hover:grayscale-0 group-hover:mix-blend-normal w-[400] h-[300] transition grayscale mix-blend-luminosity object-cover'
                            src={`${post.image}&w=800&h=600`}
                            width='400'
                            height='300'
                            alt={post.image_alt}
                            role={post.image_alt ? 'img' : 'presentation'}
                        />
                    )}
                </div>
                <div className='md:bottom-0 md:absolute md:inset-x-0'>
                    <div className='relative z-10 md:p-6 pb-3 text-background-900 text-sm md:text-foreground-100'>
                        <span className='block font-bold'>
                            { post.externalAuthor && post.author === 'Media Publication' ? post.externalAuthor : post.author } • {post.date}
                        </span>
                        <span className='block'>{post.date}</span>
                    </div>
                    <div className='md:block z-0 absolute inset-0 hidden bg-gradient-to-r from-background-600/80 to-background-600/40 opacity-100 group-hover:opacity-0 w-100 h-100 transition-opacity'></div>
                    <div className='md:block z-0 absolute inset-0 hidden bg-gradient-to-r from-accent-600/90 to-accent/75 opacity-0 group-hover:opacity-100 w-100 h-100 transition-opacity'></div>
                </div>
            </div>
            <div>
                <h3
                    id={post.title}
                    className='mb-4 font-normal text-2xl text-accent tracking-wide'
                    dangerouslySetInnerHTML={{ __html: post.title }}
                ></h3>
                <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                <div className='flex align-center'>
                    <button
                        role='button'
                        onClick={toPost}
                        className='flex items-center my-4 hover:text-background transition-colors group stretched-link'
                        aria-label={`View post: ${post.title}`}
                    >
                        Read post{' '}
                        <ArrowUpRightIcon className='group-hover:text-background ml-2 w-5 h-5 text-accent transition-colors group-hover:animate-pulse' />
                    </button>
                </div>
            </div>
        </div>
    );
}
