import React, { useEffect, useState, useRef } from 'react';
import Wrapper from '../../components/wrapper';
import PageLead from '../../components/PageLead';
import api from '../../utils/api';
import jazzHrApi from '../../utils/jazzHrApi';
import Section from '../../components/Section';
import ContentSlider from './components/ContentSlider';
import ContentSlide from './components/ContentSlide';
import ContentCard from './components/ContentCard';
import Seo from '/src/components/Seo';
import parse from 'html-react-parser/dist/html-react-parser';
import JobListing from './components/JobListing';
import JobContentDrawer from './components/JobContentDrawer';
import Compass from '../../images/SVG/ill_compass.svg';
import Asterisk from '/src/components/asterisk';
import { motion } from 'framer-motion';
import makeSeo from '../../utils/makeSeo';

const bgBlob = new URL('/src/images/postBG_blob.png?as=webp&quality=45&width=2000', import.meta.url);

const defaultSeo = {
    title: 'Careers at Archer | Archer',
    description: 'Learn about careers at Archer, an employee-owned agency.',
};

export default function Careers(props) {
    const [loaded, setLoaded] = useState(false);
    const [jobsLoaded, setJobsLoaded] = useState(false);
    const [content, setContent] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
    const [seo, setSeo] = useState(defaultSeo);
    const linkRef = useRef(null);

    const handleDrawer = (open, title, content) => {
        setDrawerOpen(open);
        setDrawerContent({
            ...drawerContent,
            title: title,
            content: content,
        });
    };

    const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            intro: {
                pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
            },
            content: data.acf.content_blocks,
            jobs_intro: data.acf.jobs_intro,
            jobs_alt: data.acf.jobs_alt,
        };
        setContent(dataContent);
    };

    // click handler for #contact-form
    const handleContactForm = (e) => {
        e.preventDefault();
        props.openContactForm();
    };

    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        api.getData('pages/5852?acf_format=standard')
            .then((response) => {
                pageContent(response.data);
                setLoaded(true);
                getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        jazzHrApi
            .getData()
            .then((response) => {
                // console.log(response.data);
                setJobs(response.data);
                setJobsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setJobsLoaded(true);
            });
    }, []);

    return (
        <>
            <Seo title={seo.title} description={seo.description} />

            <Wrapper theme='light' openContactForm={props.openContactForm}>
                {!loaded ? (
                    <div className='h-screen'></div>
                ) : (
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0, delay: 0 }}
                    >
                        {content && (
                            <motion.section
                                initial={{ opacity: 0, y: 40 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.2 }}
                                layout
                                className='relative transparent pt-44'
                            >
                                <PageLead
                                    title={content.title}
                                    titleVisible={content.intro.showTitle}
                                    subtitle={content.intro.headline}
                                    text={content.intro.content}
                                    align='left'
                                />

                                <img
                                    src={Compass}
                                    className='opacity-20 mx-auto absolute top-0 left-1/2 -translate-x-1/2 translate-y-[400px] brightness-50 scale-150 lg:scale-[2.0] lg:translate-y-1/3 lg:translate-x-[10%] z-0 select-none'
                                    draggable='false'
                                />
                            </motion.section>
                        )}
                        <motion.div
                            initial={{ opacity: 0, y: 40, rotate: 2 }}
                            animate={{ opacity: 1, y: 0, rotate: 0 }}
                            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                            layout
                            className='bg-gradient-to-b from-white to-foreground-100 relative'
                        >
                            <Section bg='transparent' loaded={loaded}>
                                <div className='lg:hidden'>
                                    <ContentSlider>
                                        {content &&
                                            content.content.map((item, index) => (
                                                <ContentSlide
                                                    key={index}
                                                    title={item.title}
                                                    description={item.description}
                                                    cta={item.cta}
                                                />
                                            ))}
                                    </ContentSlider>
                                </div>
                                <div className='hidden lg:grid md:grid-cols-2 lg:grid-cols-3'>
                                    {content &&
                                        content.content.map((item, index) => {
                                            return (
                                                <ContentCard
                                                    key={index}
                                                    index={index}
                                                    image={`${item.image.url}&w=800`}
                                                    title={item.title}
                                                    description={item.description}
                                                    cta={item.cta}
                                                />
                                            );
                                        })}
                                    <div className='px-8 flex justify-center items-center'>
                                        <Asterisk className='text-white' />
                                    </div>
                                </div>
                            </Section>
                        </motion.div>
                        {jobsLoaded && (
                            <>
                                <motion.div
                                    initial={{ opacity: 0, y: 40, rotate: 2 }}
                                    animate={{ opacity: 1, y: 0, rotate: 0 }}
                                    transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                                    layout
                                    className='bg-gradient-to-b from-white to-foreground-100'
                                >
                                    <div
                                        style={{ '--image-url': `url(${bgBlob})` }}
                                        className='md:bg-[image:var(--image-url)] bg-[length:100vw_auto] bg-no-repeat bg-[center_top_1rem]'
                                    >
                                        <Section bg='transparent' loaded={loaded}>
                                            <div className='grid lg:grid-cols-2 gap-10 lg:gap-36 max-w-none'>
                                                {content && (
                                                    <div className='prose prose-lg'>
                                                        <h3 className='text-accent-500'>
                                                            Find your path forward at one of the nation’s leading (and
                                                            largest) independent agencies.
                                                        </h3>
                                                        <p>
                                                            Explore our open listings, or{' '}
                                                            <button onClick={handleContactForm}>contact</button> one of our
                                                            passionate people leaders.
                                                        </p>
                                                    </div>
                                                )}
                                                {content && (
                                                    <div className='prose prose-lg divide-y'>
                                                        {jobs.length > 0 ? (
                                                            jobs.map((job, i) => (
                                                                <JobListing
                                                                    job={job}
                                                                    key={i}
                                                                    drawerOpen={drawerOpen}
                                                                    handleDrawer={handleDrawer}
                                                                    index={i}
                                                                />
                                                            ))
                                                        ) : Object.hasOwn(jobs, 'id') ? (
                                                            <JobListing
                                                                job={jobs}
                                                                drawerOpen={drawerOpen}
                                                                handleDrawer={handleDrawer}
                                                            />
                                                        ) : (
                                                            parse(content.jobs_alt)
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Section>
                                    </div>
                                </motion.div>
                                <JobContentDrawer open={drawerOpen} setOpen={setDrawerOpen} content={drawerContent} />
                            </>
                        )}
                    </motion.div>
                )}
            </Wrapper>
        </>
    );
}
