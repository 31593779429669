/**
 * Block | Staggered Content Block (challenge_solution)
 * 
 * @param
 * headingTop
 * HeadingStyleTop
 * challengeDescription
 * challengeImage
 * headingBot
 * headingStyleBot
 * solutionDescription
 * solutionImage
 * stagger
 */

import React from 'react';
import { motion } from 'framer-motion';

const bgStar = new URL('../../../images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);

export default function ChallengeSolution(props) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            style={{ '--image-url': `url(${bgStar})` }}
            className='bg-foreground-100
            relative overflow-hidden
            md:before:block
            before:absolute
            before:bg-[image:var(--image-url)]
            before:bg-contain
            before:bg-no-repeat
            before:inset-x-1/2
            before:-translate-y-1/2
            before:-translate-x-1/2
            before:top-1/2
            before:left-1/2
            before:w-3/4
            before:h-3/4
            md:before:w-[425]
            md:before:h-[425]
            py-8 xl:py-12'
        >
        <div className='relative container xl:max-w-7xl'>
            <div className='grid gap-4 lg:gap-x-8 lg:grid-cols-2'>
                <div className='w-full mb-6'>
                    {props.headingTop && props.headingStyleTop && props.headingStyleTop === 'h2' && (
                        <h2 className='text-3xl md:text-4xl text-accent font-normal mb-5 tracking-wide uppercase'>
                            {props.headingTop}
                        </h2>
                    )}
                    {props.headingTop && props.headingStyleTop && props.headingStyleTop === 'h3' && (
                        <h3 className='text-2xl text-accent uppercase font-normal tracking-wide mb-5'>
                            {props.headingTop}
                        </h3>
                    )}
                    <div
                        className='prose prose-lg'
                        dangerouslySetInnerHTML={{ __html: props.challengeDescription }}
                    />
                </div>
                <div className={`w-full mb-6 justify-center text-center ${props.stagger ? 'lg:row-span-2' : ''}`}>
                    {props.challengeImage && (
                        <img
                            src={props.challengeImage['url']}
                            alt={props.challengeImage['alt']}
                            className='w-3/5 lg:w-auto mx-auto'
                        />
                    )}
                </div>
                <div className={`w-full mb-6 lg:order-4 ${props.stagger ? 'lg:row-start-3 lg:col-start-2' : ''}`}>
                    {props.headingBot && props.headingStyleBot && props.headingStyleBot === 'h2' && (
                        <h2 className='text-3xl md:text-4xl text-accent font-normal mb-5 tracking-wide uppercase'>
                            {props.headingBot}
                        </h2>
                    )}
                    {props.headingBot && props.headingStyleBot && props.headingStyleBot === 'h3' && (
                        <h3 className='text-2xl text-accent uppercase font-normal tracking-wide mb-5'>
                            {props.headingBot}
                        </h3>
                    )}
                    {props.solutionDescription && (
                        <div
                            className='prose prose-lg'
                            dangerouslySetInnerHTML={{ __html: props.solutionDescription }}
                        />
                    )}
                </div>
                <div className={`w-full mb-6 lg:order-3 ${props.stagger ? 'lg:row-start-2 lg:col-start-1 lg:row-span-2' : ''}`}>
                    {props.solutionImage && (
                        <img
                            src={props.solutionImage['url']}
                            alt={props.solutionImage['alt']}
                            className='w-3/5 lg:w-auto mx-auto'
                        />
                    )}
                </div>
            </div>
        </div>
        </motion.section>
    )
}
