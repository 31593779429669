/**
 * Block | Bulleted Content Block (results_block)
 * 
 * @param
 * heading
 * headingStyle
 * description
 * image
 * imagePosition
 * fullBleed
 * bullets
 * descriptionBottom
 */

import React from 'react';
import { motion } from 'framer-motion';
import BulletedList from '../components/BulletedList';
import Starline from '../components/Starline';

export default function Results(props) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className='bg-foreground-100
            relative
            py-8 xl:py-12
            overflow-hidden'
        >
        <div className="relative container xl:max-w-7xl overflow-visible z-10">
            <div className={`${props.fullBleed ? 'lg:container-bleed' : ''}`}>
                <div className={`${props.fullBleed ? 'block lg:flex lg:justify-between' : 'grid gap-6 xl:gap-36 lg:grid-cols-2'}`}>
                    <div className={`${props.fullBleed ? 'w-full' : ''}`}>
                        <div className={`${props.imagePosition ? 'order-1' : 'order-1 lg:order-2'}`}>
                            {props.heading && props.headingStyle && props.headingStyle === 'h2' && (
                                <h2 className='text-3xl md:text-4xl text-accent uppercase font-normal tracking-wide mb-5'>
                                    {props.heading}
                                </h2>
                            )}
                            {props.heading && props.headingStyle && props.headingStyle === 'h3' && (
                                <h3 className='text-2xl text-accent uppercase font-normal tracking-wide mb-5'>
                                    {props.heading}
                                </h3>
                            )}
                            {props.description && (
                                <div
                                    className={`prose prose-lg`}
                                    dangerouslySetInnerHTML={{ __html: props.description }}
                                />
                            )}
                            {props.bullets && <BulletedList list={props.bullets} />}
                            {props.additionalContent && props.descriptionBottom && (
                                <div
                                    className='prose prose-lg pt-6'
                                    dangerouslySetInnerHTML={{ __html: props.descriptionBottom }}
                                />
                            )}
                        </div>
                    </div>
                    {props.image && (
                        <div className={`${props.fullBleed ? 'hidden xl:block w-full' : 'block'} ${props.imagePosition ? 'order-2' : 'order-2 lg:order-1'}`}>
                            <img
                                src={props.image['url']}
                                alt={props.image['alt']}
                                className={`${props.fullBleed ? 
                                             `${props.imagePosition ? 'ml-auto' : 'mr-auto'}` : 
                                             `mx-auto ${props.imagePosition ? 'lg:ml-auto' : 'lg:mr-auto'}`}
                                             xl:pl-2 2xl:max-h-[600px] w-full max-w-xl select-none`}
                                draggable='false'
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
        <Starline opacity='100' sharp />
        </motion.section>
    )
}
